import React from 'react';

// TODO: This form needs a `mailingListLink` variable set
// This gives a unique identifier so we know where people are signing up from
// See index.js

const SubscribeForm = props => {
  const { mailingListLink } = props;
  const url = `https://andyet.us9.list-manage.com/subscribe/post?u=faa323952110d5be6830f05f5&amp;id=3aad4805ae&SIGNUP=${mailingListLink}`;

  return (
    <div className="form-inline" id="mc_embed_signup">
      <form
        className="validate"
        action={url}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate="novalidate"
      >
        <fieldset id="mc_embed_signup_scroll">
          <div className="mc-field-group form-element">
            <label className="left" htmlFor="mce-FNAME">
              Name
            </label>
            <input
              className="form-input"
              type="text"
              name="FNAME"
              id="mce-FNAME"
              placeholder="What can I call you?"
            />
          </div>
          <div className="mc-field-group form-element">
            <label className="left" htmlFor="mce-EMAIL">
              Email address*
            </label>
            <input
              className="form-input required email"
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              placeholder="you@reawesome.com"
            />
          </div>
          <div className="mc-field-group input-group" id="subscribe-interests">
            <label>I’m interested in</label>
            <ul className="list-unstyled">
              <li className="cf">
                <input
                  type="checkbox"
                  value="1"
                  name="group[38009][1]"
                  id="mce-group[38009]-38009-0"
                  checked="checked"
                  readOnly
                />
                <label htmlFor="mce-group[38009]-38009-0">
                  Updates from the &amp;yet team
                </label>
              </li>
              <li className="cf">
                <input
                  type="checkbox"
                  value="2"
                  name="group[38009][2]"
                  id="mce-group[38009]-38009-1"
                  checked="checked"
                  readOnly
                />
                <label htmlFor="mce-group[38009]-38009-1">
                  Education on strengthening customer relationships through
                  creative technology
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  value="4"
                  name="group[38009][4]"
                  id="mce-group[38009]-38009-2"
                />
                <label htmlFor="mce-group[38009]-38009-2">
                  Goals masterclass
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  value="8"
                  name="group[38009][8]"
                  id="mce-group[38009]-38009-3"
                />
                <label htmlFor="mce-group[38009]-38009-3">
                  People-first resources for gathering remotely
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  value="16"
                  name="group[38009][16]"
                  id="mce-group[38009]-38009-4"
                  checked="checked"
                  readOnly
                />
                <label htmlFor="mce-group[38009]-38009-4">
                  Gather the People
                </label>
              </li>
            </ul>
          </div>
          <div className="clear" id="mce-responses">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
          </div>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_faa323952110d5be6830f05f5_3aad4805ae"
              tabIndex="-1"
            />
          </div>
          <div className="clear center">
            <input
              className="button button-green button-large"
              type="submit"
              value="Keep me posted"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default SubscribeForm;
