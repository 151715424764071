import React from 'react';

const Testimonials = props => {

  return (
    <section className="testimonials">
      <div className="wrapper" id="testimonials">
        <h2>Praise for Gather the People</h2>

        <blockquote>
          <h5>“I just finished reading Gather the People in one big gulp, at last—and loved it. It’s helping me feel ready (excited, even) for the launch of my new site and identity. “What is most true for me right now?”, and the questions that follow, are so golden in their evergreenness. And all the many lists give me such a window into your world as it was when you wrote the book. I experienced them as deeply generous.</h5>

          <h5>Then, reading the resources section at the end of the book, I realized how many of the books I’ve read already…and then realized, that’s because so many of them came from you. My ‘Consider: Reading’ context in OmniFocus—one of the only contexts that’s reliable at all!—is full of book names with ‘recommended by Sarah Avenir appended. When it comes from you, I’m much more likely to grab it.</h5>

          <h5>Thank you for being consistent and setting an example of gentleness + ambition—all the loops and cycles it takes to begin, over and over again.”</h5>

          <footer><a href="https://twitter.com/dianakimball">Diana Kimball Berlin</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>I started reading Gather the People and couldn’t put it down. </strong> I felt like the author, Sarah Avenir, was speaking right to my heart. So of course, I stayed up late to finish it.</h5>

          <h5>It’s basically about not making things in a vacuum, which is just the kick in the pants I needed. Although I know that well when it comes to helping other people with their products and companies, I think I had lost sight of it for my own creative work. I realized there are so many things I’ve been thinking about making (or writing, mainly). Why not just write them, put them in the world, and see if anything resonates?”</h5>

          <footer><a href="https://twitter.com/lmsanchez">Lisa Sanchez</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong> I recommend, once again, Sarah Avenir’s Gather the People.</strong> A great takeaway from the book is the exercise of asking yourself what you want...but then WHY you want it.”</h5>
          <footer><a href="https://twitter.com/laurabalaurah">Laura Holway</a></footer>
        </blockquote>

        <blockquote>
          <h5>“I read your book on the plane! MIND. BLOWN. I love this!”</h5>
          <footer><a href="https://twitter.com/heyshenee">Shenee Howard</a></footer>
        </blockquote>

        <blockquote>
          <h5>“Gather the People feels like it was written just for me, for this very moment in my life. ;__;”</h5>
          <footer><a href="https://twitter.com/fet">William Van Hecke</a></footer>
        </blockquote>

        <blockquote>
          <h5>“If you could see my book shelf, you would see a tattered, worn, and marked-up copy of Gather the People. I’ve poured over the pages, read and re-read it multiple times. <strong>This is the most powerful book on marketing I’ve ever come across.</strong> It helped me to see a new way of creating and promoting that is not only more human but incredibly effective!”</h5>
          <footer>Erin Anacker</footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>You’re one of the most conscious, introspective writers-about-business I know.</strong> I love how you create an intimate setting for sharing your truth and how useful your insights are!”</h5>
          <footer><a href="https://twitter.com/NathLussier">Nathalie Lussier</a></footer>
        </blockquote>

        <blockquote>
          <h5>"So many ahas and takeaways — just brilliant! 48 hours ago I realized what I’d been building wasn’t quite right. <strong>Thanks to you, I have loads of great ideas and a new method for building it.</strong> If I pull it off, it’ll mean the realization of a dream that’s been percolating for years!”</h5>
        </blockquote>

        <blockquote>
          <h5>“<strong>Best business dollars you may ever spend.</strong> So. Serious.”</h5>
        </blockquote>

        <blockquote>
          <h5>“I don’t think I have ever known anybody as creative and loving as you. Not creative. Not loving. Creative-and-loving, like it’s one word. Over the years, I watch your projects and I’m so impressed by how YOU you are, every single time. You give and you give and you give and you give and you just don’t freaking quit, and the world is nicer because you are in it. Thank you for making Gather The People, thank you for staying you all these years, and thank you for the inspiration.</h5>
          <h5>We printed your book and then printed it again so we wouldn’t have to share a copy. (Our highlights were starting to compete, and we fight when that happens. Best to just avoid the whole thing.) It was a real inspiration for change, and I’ve recommended it to three clients already. Well done, my dear.”</h5>
          <footer><a href="https://twitter.com/NaomiDunford">Naomi Dunford</a></footer>
        </blockquote>

        <blockquote>
          <h5>“I just wanted to say thank you so much for writing Gather The People. I’ve been a silent reader of your emails since right before the Tour de Bliss and I have always appreciated and enjoyed how human your writing is — useful and personal at the same time. And I always wondered how you told such lovely stories and made it look so effortless.</h5>

          <h5>I’ve definitely found myself in the position of the maker who’s worked silently behind the scenes and then released stuff to crickets because the idea of doing a whole big launch is just kinda off-putting to me because it feels like the focus is on me and not them.</h5>

          <h5><strong>I really appreciate the concept of bringing people along on the journey and how clearly you outline that process.</strong> I already have a flag in the ground and a wee mailing list, so I’m going to be shifting how I approach my communications going forward.</h5>

          <h5>Thank you so much for writing an ebook that not only changed my thinking but it is also incredibly practical. <strong>It really is the best ebook I’ve ever purchased.</strong>”</h5>

          <footer>Miki DeVivo</footer>
        </blockquote>

        <blockquote>
          <h5>”Can I highlight entire pages? So many gems. <strong>Read ASAP if you do any kind of creative work.</strong>”</h5>
        </blockquote>

        <blockquote>
          <h5>"Your book deeply resonated with my own philosophy of audience-building and it felt like a beautiful affirmation of some key spiritual truths about this work of online business and community that I’ve suspected for a long time but hardly had articulated.</h5>

          <h5><strong>I’ll be showing my students your work as an example of what solid online leadership looks like.</strong></h5>

          <h5>Thank you deeply for summoning the courage to write with kindness and compassion and open-handed transparency – in an industry where a lot of “leaders” give off a saccharine pump-you-up kind of vibe that I can’t hang with, it was just really refreshing to read a thoughtful work from someone who’s been in the self-employed internet trenches for a substantial time and has her heart and wits fully intact.”</h5>

          <footer>Carolyn Elliott</footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>Your book is awesome and has totally transformed the things I’m working on.</strong>”</h5>
        </blockquote>

        <blockquote>
          <h5>“WOW is this an embodiment of SO MUCH of what makes you AMAZING and AWESOME and OTHER ALL-CAPS things!!!!”</h5>

          <footer><a href="https://twitter.com/DanBlank">Dan Blank</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>This is going to go down as one of the most important books on my mental shelf.</strong> I’m going to come back to it over and over and over again.”</h5>

          <footer><a href="https://twitter.com/adambrault">Adam Brault</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>I couldn’t read fast enough.</strong> Bravo Sarah. This is a remarkable amalgam of everything about you. I am so proud and in such awe.”</h5>
        </blockquote>

        <blockquote>
          <h5>“I’ve followed your work for a couple of years now. When I read your preview I knew by the second sentence that I was buying your book.</h5>

          <h5><strong>Your work...your words...your vision are nothing less than beautiful and inspiring.</strong></h5>

          <h5>Thank you for being willing to share who you are so openly and with such vulnerability. You truly are changing the world.”</h5>

          <footer>Tamara Suttle</footer>
        </blockquote>

        <blockquote>
          <h5>“I spent most of the morning reading and am so IN LOVE! <strong>More in love with a business book than I have been in a long time.</strong> And I like business books! But I LOVE Sarah J. Avenir :)  Thank you for a GREAT read!”</h5>

          <footer>Melanie Mauer</footer>
        </blockquote>

        <blockquote>
            <h5>”<strong>Just read the preview.</strong> It’s amazing.”</h5>
          </blockquote>

        <blockquote>
            <h5>“You are a marvel! What a gift you have at zeroing in on just what’s needed. <strong>You have inspired, energized and made me feel that good things could really happen.</strong>”</h5>

            <footer>Trish Prentice</footer>
          </blockquote>

        <blockquote>
          <h5>“I am savoring your book like the most delectable chocolate bar. You have done a fantastic job.”</h5>
        </blockquote>

        <blockquote>
          <h5>“I’ve had the privilege of seeing early drafts of Sarah Avenir’s new book, and I have to say, it’s been so helpful to me already.</h5>

          <h5><strong>Sarah writes about the intersection between creating and releasing your work – how to create in a way that makes people care, even before you’re finished.</strong> For those of us whose natural tendency is to hide away during the making phase, she calls us to bring people along on the process.”</h5>
        </blockquote>

        <blockquote>
          <h5>“<strong>A thoughtful look at the power of gathering people, beautifully done!</strong>”</h5>

          <footer><a href="https://twitter.com/ohmyhandmade">Jessika Hepburn</a></footer>
        </blockquote>

        <blockquote>
          <h5>“Just wanted to let you know I finished the book a few days ago. There’s so much good stuff in there and I took so many notes while reading. <strong>It really helped me to get some clarity about my current projects and possible future ones.</strong>”</h5>

          <footer><a href="https://twitter.com/christinebougie">Christine Bougie</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>Sarah, I read the whole book in one sitting! So good.</strong> And definitely at the right moment for me.</h5>

          <h5>You’re such an inspiration. I have a busy day job and some ambitious side projects <strong>so this could not come at a better time.</strong></h5>

          <h5>I have hit many of the blocks you describe in the book (i.e. sitting on a not-quite-done project for a loooong time, making excuses, getting in my own way).</h5>

          <h5>I look forward to reading it again in much more detail, scribbling my own answers to many of these same questions. I’m definitely not starting from scratch, since I have all of the raw ingredients you mention already. Thank you for your honesty and for sharing your stories!”</h5>

          <footer><a href="https://twitter.com/leslieforman">Leslie Forman</a></footer>
        </blockquote>

        <blockquote>
          <h5>“You have crafted a beautiful book. I appreciate your openness, the honesty which inspires, and the clear simplicity without pulling any punches. I’m impressed.</h5>

          <h5><strong>Two chapters and a prologue in and I feel helped already.</strong> What perfect timing. Life as a creative is hard. Starving is not the way I prefer my art to be created.”</h5>

          <footer>Naomi Tucker</footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>Gather the People by Sarah J. Avenir = quietly revolutionary.</strong> I urge you to read it.”</h5>

          <footer>Tessa Zeng</footer>
        </blockquote>

        <blockquote>
          <h5>“A good friend forwarded a link to one of your blog articles. I proceeded to read your blog for hours & hours. It all moved me...literally to tears (kind of embarrassing to admit!).</h5>

          <h5>What really got me was maybe less about the specific content and more about the honesty and transparency of your approach to the web. Eureka! People can have integrity, honesty and show their colors in the business world????</h5>

          <h5>Long story short: I found a renewed inspiration for the web, quit my job and started my own business. Holy crap that was scary! I would be lying if I said it has gone perfectly....but needless to say, <strong>I am sooooo much happier in life, am proud once again of the work I do</strong> and wanted to reach out to you to let you know that you played a part in that huge life change.</h5>

          <h5>You’re a great writer and although we haven’t met, your personality comes through very transparently and I’ve been regularly encouraged by your words. Keep up the great work!”</h5>

          <footer>Rodney Warner</footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>You are a great writer and your content is always fresh!</strong> And, you inspire me to continue on the track of never becoming dull in the repetitive world of corporate America!”</h5>

          <footer>Norma Riehle</footer>
        </blockquote>

        <blockquote>
          <h5>“I’ve been on the Internet since 1996. I’ve been an entrepreneur for 5 years. <strong>Your thinking, your spirit, your intelligence, your wisdom, your vulnerability truly move me more deeply than anything I’ve ever read.</strong> You are a marvel, Sarah.”</h5>
        </blockquote>

        <blockquote>
          <h5>“I really adore your voice. <strong>You read as a real, live human being with ideas and emotions and fears and beliefs...</strong> you aren’t afraid to share about your mistakes and you seem to embrace the fact that you’re always learning. You’re very approachable and somehow manage to come across as selfless, even as you share your own evolving story with us. The ‘I’ in your writing = the ‘me’ as the reader... that balance is always there (as opposed to others for whom the equation looks more like ‘I’/writer &gt; ‘me’/reader, and from whom I’ve unsubscribed.</h5>

          <h5>Thanks for respecting us as peers and valuable contributors to your work.”</h5>

          <footer>Kristen Stancik</footer>
        </blockquote>

        <blockquote>
          <h5>“I LOVE your writing.  It feels like you are writing to just me. Like we’re good friends. (and now we actually ARE! yay for US!!) But before we were for reals, I felt like every time I read your work, I either 1) laughed (favorite!), 2) related to one of your struggles and no longer felt alone (so important) 3) learned something new or 4) I get my fix on the next chapter of whatever is happening - like a good soap opera or reality TV show.  I’ll just say it...maybe I’m addicted. There is always something of value.  <strong>I always feel like you ACTUALLY care about me and you’re not just talking to me so that I’ll buy something from you.</strong> (Although, I always want to, because you deliver high value.) It’s always like getting a handwritten card, instead of a piece of junk mail. Who doesn’t want to open handwritten mail? I hope to communicate 1/2 as well as you, Sarah, when I grow up.</h5>

          <h5>I know other people say this, but I truly am your #1 fan and I’d go into the fighting ring to prove it. Who wants a piece of me?!”</h5>

          <footer>Treacy Mize</footer>
        </blockquote>

        <blockquote>
          <h5>“I need to know that I am not the only crazy entrepreneur out there. Your off-the-cuff nutty writing is reassuring. You also have a crazy life!”</h5>

          <footer>Karyn Wagner</footer>
        </blockquote>

        <blockquote>
          <h5>“When I see anything written by ‘Sarah J. Avenir’ it’s not just that I open it, I get really excited to open it! <strong>I know that whatever is inside is going to be something that I can relate to, something that will help me on my path, something that will give me an inside look into the life of someone I admire, someone who motivates and inspires.</strong> And it never fails - it does. :)</h5>

          <h5>You’re awesome. That’s pretty much all there is to it. :D”</h5>

          <footer><a href="https://twitter.com/coyotewitch">Nikki Jeske</a></footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>It feels like a slumber party with business owners only invited,</strong> and I get to listen to you talk about stuff that no one talks about but I’m always thinking about, and I feel validated & like everything I’ve been experiencing is legitimate & I’m not a freak, and it’s inspiring because you’re always solving your own problems, and usually they were my problems, too, and I love being a part of that conversation.”</h5>

          <footer>Kelly Parkinson</footer>
        </blockquote>

        <blockquote>
          <h5>“I don’t know for sure if this is a common experience or only mine, but I find your writing rejuvenating. <strong>You write with such candidness and transparency that the lessons you pass on build me up like a conversation with a close friend.</strong> I finish your work feeling lighter.”</h5>

          <footer>Stephanie Peterson</footer>
        </blockquote>

        <blockquote>
          <h5>“When I read your work, I can count on <strong>thought-provoking, well-written content that helps me create in new and different ways.</strong> It makes me feel ‘in th know’ and the content does not appear to be solely generated to facilitate a sales pitch.”</h5>

          <footer>Heather Mullane Bean</footer>
        </blockquote>

        <blockquote>
          <h5>“I connect with the depth of your message and that makes reading anything you send out a pleasure.”</h5>

          <footer>Bernardo Mendez</footer>
        </blockquote>

        <blockquote>
          <h5>“I am one of those people that always reads your work. I like it because you share your vulnerable self and I can connect. Then you share a learning that you gained from your experience. I don’t even know you in person, but I consider you a very down to earth person and therefore I feel very much connected to you, because you invite me to share in your experiences with you through your writing.</h5>

          <h5>So your writing is presented in a way where I feel I can connect and I take away something. I like it!”</h5>

          <footer>Giusep Michelle</footer>
        </blockquote>

        <blockquote>
          <h5>“I read your work because I invariably find something interesting and real in what you’ve written. Your voice is so unfiltered and your story is dramatic but always hopeful. <strong>I really like that your process of business building is intuitive and organic, and yet so successful.</strong> Your messages give off a beautiful sheen of love, for yourself, your clients, and your work.”</h5>

          <footer>Alison Gresik</footer>
        </blockquote>

        <blockquote>
          <h5>“It’s your quirkiness.  You always have an interesting way of seeing things and you communicate what you’ve been going through in a way that is relatable and helps me think about my own situation in a way I hadn’t before.”</h5>

          <footer>Sundara</footer>

        </blockquote>

        <blockquote>
          <h5>“I read your work because what I am doing isn’t working so far. I look for new information, I know there is a huge amount of patience and faith required in starting up your own business, but I want students, and I want students in a big way. I feel like a Who in Whoville wanting, trying to be heard by Horton. I want my one mosquito buzz to sound like a jet engine. <strong>I read your writing to look for new ways to get heard.</strong>”</h5>

          <footer>Cherol Ockrassa</footer>
        </blockquote>

        <blockquote>
          <h5>“Your writing is so good and I always get inspired or a fresh perspective/ twist on how to look at something.”</h5>

          <footer>Renee Landry</footer>
        </blockquote>

        <blockquote>
          <h5>“I read your delicious writing because it’s a chance to hear from one of the more interesting people I’ve been privileged to ‘meet’ on the internet. I love the way you think about things -- and they way you share those thoughts so generously in your e-mail updates feels like getting to read your business diary. You make me laugh, and you make me think. <strong>You help me imagine new possibilities for my own business.</strong></h5>

          <h5>Your writing never feels like business. It feels like getting to know *you.*”</h5>

          <footer>Christianne Squires</footer>
        </blockquote>

        <blockquote>
          <h5>“I read your writing because I’m sure to find your unique and idiosyncratic take on doing your creative work in the world, your way, for the people you love to serve. I love your voice and I respect the way you do business.”</h5>

          <footer><a href="https://twitter.com/TheVoiceBureau">Abby Kerr</a></footer>
        </blockquote>

        <blockquote>
          <h5>“Your writing is just so real! It’s like getting a letter from a friend. A particularly fun, interesting and insightful friend.”</h5>

          <footer>Sarah Goshman</footer>
        </blockquote>

        <blockquote>
          <h5>“You have integrity.</h5>

          <h5><strong>I feel a kinship with you and your many directions, ideas, brainstorms and efforts to figure it all out and make a contribution in the best way possible.</strong> I have tons of new ideas and reinventions of my self and my businesses.</h5>

          <h5>You are not just trying to sell me stuff and you value my time.”</h5>

          <footer>Miven Trageser</footer>
        </blockquote>

        <blockquote>
          <h5>“You have the most unique voice! It’s so totally...you. ;-P</h5>

          <h5>And it seems like every time you are writing about something that I’ve been thinking about but didn’t even know it.”</h5>

          <footer>cj Madigan</footer>
        </blockquote>

        <blockquote>
          <h5>“Sarah, I read all of your writing because you write like a real person, not a marketing robot. It feels like getting a handwritten note in the mail from someone I met recently and really liked—a person I haven’t gotten to know in depth yet, but would like to.”</h5>

          <footer>Tracy Hart</footer>
        </blockquote>

        <blockquote>
          <h5>“<strong>Gather the People is one of those books I’ve returned to again and again.</strong> When I’m tired of the bs and buzzwords, Sarah reminds me, in her wise and vital voice, that what matters: in your creativity and your marketing, in the beginning and the end, is putting people first. If that’s a reminder you need, you should get this book.”</h5>

          <footer>Guan Un</footer>
        </blockquote>

        <blockquote>
          <h5>“I had to stop at almost every other page while reading Gather the People because <strong>the ideas in there would get me so excited to work on my own stuff.</strong> What would happen is that I would read a little bit of Gather the People then stop a bit to digest and take down notes. I finally got to finish it on a 12-hour train ride, and I was sad because I wanted to read more! Now it’s time to take action.”</h5>

          <footer>Jag Talon</footer>
        </blockquote>
      </div>
    </section>
  );
};

export default Testimonials;
